<template>
  <div>
    <template v-if="players.length">
      <div class="absolute top-0 left-0 p-4 text-left">
        <h3 class="mb-2">players</h3>
        <div
          v-for="player in players"
          :key="player.name"
          :class="{ 'color-purple-6': player.id === turn }"
          class="flex align-center justify-start"
        >
          {{ player.name }}
          <fa-icon v-if="player.id === out" class="ml-2 size-3" icon="star" />
        </div>
      </div>

      <div class="absolute top-0 right-0 p-4 text-right">
        <h3 class="mb-2">score</h3>
        <transition-group>
          <div
            v-for="player in scores"
            :key="player.name"
            class="score flex align-center justify-end"
          >
            <fa-icon
              class="edit mr-2 size-3"
              icon="pen"
              @click="editScore(player.id)"
            />
            {{ player.name }} {{ player.score }}
          </div>
        </transition-group>
      </div>
    </template>

    <div v-if="type === null" style="transform: rotate(90deg)">
      <card type="five-crowns" class="deck" @click="init('five-crowns')" />
      <card type="quiddler" class="deck" @click="init('quiddler')" />
    </div>

    <div v-else-if="round === null" class="size-7">
      <h1>{{ code }}</h1>
      <div class="size-5 mb-4">
        {{ players.length }} player{{ players.length === 1 ? '' : 's' }}
      </div>
      <button class="btn" @click="start">start</button>
    </div>

    <template v-else>
      <div class="absolute bottom-0 left-0 p-4 size-5">{{ code }}</div>

      <transition-group name="fade">
        <div v-if="winner" key="gameover">
          <h2>Game Over</h2>
          <div class="size-5">{{ winner }} won!</div>
          <button class="btn" @click="reset">new game</button>
        </div>

        <div v-else-if="round" key="round" class="w-full">
          <h2>Round {{ round }}</h2>
          <div class="size-5 mb-4">
            <template v-if="turn !== -1">
              {{ players[turn].name }}'s turn
            </template>
            <template v-else>{{ players[dealer].name }} is dealer</template>
          </div>

          <transition-group name="fade">
            <card key="deck" :type="type" class="deck" />
            <div v-if="trash.id" key="trash" class="inline-block relative">
              <transition name="draw" mode="in-out" :key="trash.id">
                <card
                  v-bind="trash"
                  :key="trash.id"
                  :type="type"
                  class="deck"
                />
              </transition>
            </div>
          </transition-group>
          <div>{{ deck }} in deck</div>
        </div>

        <div key="last">
          <transition name="fade-up">
            <div v-if="last" :key="last.id" class="w-full pt-8">
              <div class="hand size-1">
                <div
                  v-for="(set, i) of last.hand"
                  :key="i"
                  class="set inline-block"
                >
                  <card
                    v-for="card of set"
                    :key="card.id"
                    :type="type"
                    v-bind="card"
                  />
                </div>
              </div>
              {{ players[last.id].name }}'s hand (+{{ last.score }})
            </div>
          </transition>
        </div>
      </transition-group>
    </template>
  </div>
</template>

<script>
import game from '@/game'
import Card from '@/components/Card'

window.game = game

export default {
  components: {
    Card
  },

  data: () => ({
    code: '',
    dealer: null,
    deck: 0,
    last: null,
    out: null,
    players: [],
    round: null,
    trash: {},
    turn: null,
    type: null,
    winner: null
  }),

  computed: {
    scores() {
      const dir = this.type === 'quiddler' ? -1 : 1
      return this.players
        .slice()
        .sort((a, b) => dir * (a.score < b.score ? -1 : 1))
    }
  },

  async mounted() {
    const copy = data => Object.assign(this, data)
    const commands = ['game', 'players']
    commands.forEach(command => game.on(command, copy))

    game.on('error', ({ message }) => {
      this.$toasted.show(message)
    })

    game.on('out', data => {
      this.last = data
    })

    game.on('draw', ({ trash }) => {
      if (trash) this.trash = {}
      else this.deck--
    })

    game.on('sync', async data => {
      if (this.last && data.out === -1 && data.turn !== -1) {
        this.last = null
        this.trash = {}
        await this.$nextTick()
      }
      copy(data)
    })

    game.on('game over', () => {
      this.winner = this.scores[0].name
    })

    await game.connect()
  },

  beforeDestroy() {
    game.all.clear()
  },

  methods: {
    editScore(id) {
      let score = prompt('New score:')
      if (/^[-+]/.test(score)) {
        const prev = this.players.find(p => p.id === id).score
        score = eval(prev + score)
      } else {
        score = +score
      }
      const ok = confirm('Confirm score: ' + score)
      if (ok) game.send('score', { id, score })
    },

    init(type) {
      this.type = type
      game.send('host', { type })
    },

    reset: () => game.send('reset'),
    start: () => game.send('start')
  }
}
</script>

<style lang="scss" scoped>
.score {
  &:not(:hover) .edit {
    visibility: hidden;
  }

  .edit {
    cursor: pointer;
  }
}

.deck {
  font-size: 1.5rem;
  margin: 0.5rem;
  transition: all 300ms ease;
}

.hand {
  margin-left: 2.5em;

  .set {
    margin-right: 10em;
    margin-bottom: 1em;
  }

  .card {
    margin-top: 0;
    margin-left: 0;
    margin-right: -7.5em;
  }
}
</style>
